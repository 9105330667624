import React from 'react'
import { any, array, bool, string } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { titleCase } from '@helpers/string-helper'
import { styled } from '@mui/material'

const ItemListContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  paddingTop: '16px',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  alignItems: 'start',
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  padding: '16px 0',
}))

const StyledHeader = styled('h3')(() => ({
  fontSize: '16px',
  fontWeight: 600,
}))

const RoomItemList = ({
  items,
  fullWidth,
  hideBorders = false,
  hideTitles = false,
  text = 'This room includes',
  packageSkuMap,
}) => {
  // const classes = useStyles({ fullWidth })

  if (!items || items.length === 0) {
    return null
  }

  const isProductIsComplete = item => {
    const numberOfPieces = Object.values(packageSkuMap).filter(value => value === item.sku).length
    return numberOfPieces === item.subItems.length
  }

  return (
    <ItemListContainer>
      <StyledHeader className="addon-header" style={{ padding: 0 }}>
        {text}:
      </StyledHeader>

      <StyledGrid container item direction="column" xs={12} md={fullWidth ? 12 : 8} spacing={2}>
        <Grid container item direction="row" justifyContent="center" alignItems="center" spacing={1}>
          {items.map(item => {
            if (typeof item === 'string') return `${item} `

            const itemSrc = item.image ? `${item.image}&h=50` : item?.imageUrl ?? null
            return (
              <Grid item key={item.sku} xs={6} md>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
                  {item?.subItems && !isProductIsComplete(item) ? (
                    <Grid item>
                      <span>{[...new Set(item.subItems)].reduce((acc, next) => `${acc}, ${next}`)}</span>
                    </Grid>
                  ) : (
                    <>
                      <Grid item>
                        <picture style={{ maxHeight: '60px', display: 'block', width: 'fit-content' }}>
                          <source srcSet={itemSrc} />
                          <img
                            alt={item.title}
                            src={itemSrc}
                            role="presentation"
                            aria-hidden="true"
                            style={{ objectFit: 'contain', height: 'auto', width: 'auto', maxHeight: '60px' }}
                          />
                        </picture>
                      </Grid>
                      {!hideTitles && (
                        <Grid item>
                          <span>{`${titleCase(item?.generic_name || item?.title || '')}${
                            item.quantity > 1 ? ` (${item.quantity})` : ''
                          }`}</span>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </StyledGrid>
    </ItemListContainer>
  )
}

RoomItemList.propTypes = {
  fullWidth: bool,
  hideBorders: bool,
  text: string,
  packageSkuMap: any,
  items: array,
  hideTitles: bool,
}

RoomItemList.defaultProps = {}

export default RoomItemList
